import { useState } from "react";
import CardSlot from "./CardSlot";
import DamagePanel from "./DamagePanel";
import Box from "./Box";
import Logo from "./Logo";
import "./App.css";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Notes from "./Notes";

function App() {


  const theme = createTheme({
    palette: {
      fire: {
        main: '#f44336',
        contrastText: '#fff',
      },
      water: {
        main: '#2196f3',
        contrastText: '#fff',
      },
      grass: {
        main: '#4caf50',
        contrastText: '#fff',
      },
      electric: {
        main: '#ffea00',
        contrastText: '#fff',
      },
      rock: {
        main: '#ffc107',
        contrastText: '#fff',
      },
      ice: {
        main: "#a7ffeb",
        contrastText: "#fff"
      },
      psychic: {
        main: "#ed4b82",
        contrastText: "#fff"
      },
      fairy: {
        main: "#f381a7",
        contrastText: "#fff"
      },
    },
  });

  const StatsBase = (init_value) => {
    return {
      hp:  init_value,
      atk: init_value,
      def: init_value,
      spa: init_value,
      spd: init_value,
      spe: init_value
    }
  }
  
  let PokemonBase = {
    uuid: "",
    nature: "Serious",
    name: "",
    species: "none",
    level: 50,
    animation: "ani/none.gif",
    stats: StatsBase(0),
    ivs: StatsBase(31),
    evs: StatsBase(0),
    boosts: StatsBase(0),
    moves: [],
    abilities: [],
    moveset: ["", "", "", ""],
    ability: "",
    item: "",
    types: [],
    originalCurHP: 0,
    isDynamax: false,
    moveCrit: [false, false, false, false]
  };

  let SideBase = {
    isAuroraVeil: false,
    isBattery: false,
    isForesight: false,
    isFriendGuard: false,
    isHelpingHand: false,
    isLightScreen: false,
    isProtected: false,
    isReflect: false,
    isSR: false,
    isSeeded: false,
    isSwitching: false,
    isTailwind: false,
    spikes: 0
  }

  let fieldBase = {
    gameType: "Doubles",
    isGravity: false,
    terrain: null,
    weather: null
  }

  const [pokemonSlot1, setPokemonSlot1] = useState(PokemonBase);
  const [sideSlot1, setSideSlot1] = useState(SideBase);
  const [pokemonSlot2, setPokemonSlot2] = useState(PokemonBase);
  const [sideSlot2, setSideSlot2] = useState(SideBase);
  const [pokemonBox, setPokemonBox] = useState([]);
  const [field, setField] = useState(fieldBase);
  const [damageList, setDamageList] = useState([]);
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Logo />
        <Box
          pokemonBase={PokemonBase} pokemonBox={pokemonBox} setPokemonBox={setPokemonBox} 
          pokemonSlot1={pokemonSlot1} setPokemonSlot1={setPokemonSlot1} 
          pokemonSlot2={pokemonSlot2} setPokemonSlot2={setPokemonSlot2} 
        />
        <CardSlot
          slot_id={"slot1"}
          pokemon={pokemonSlot1} setPokemon={setPokemonSlot1} pokemonBase={PokemonBase} side={sideSlot1} setSide={setSideSlot1} setPokemonBox={setPokemonBox}/>
        <DamagePanel 
          p1={pokemonSlot1} p2={pokemonSlot2} setP1={setPokemonSlot1} setP2={setPokemonSlot2}  field={field} setField={setField} side1={sideSlot1} side2={sideSlot2} setDamageList={setDamageList}/>
        <CardSlot 
          slot_id={"slot2"}
          pokemon={pokemonSlot2} setPokemon={setPokemonSlot2} pokemonBase={PokemonBase} side={sideSlot2} setSide={setSideSlot2} setPokemonBox={setPokemonBox}/>
        <Notes damageList={damageList} setDamageList={setDamageList} />
      </ThemeProvider>
    </div>
  );
}

export default App;
