import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import "./Side.css";

const Side = ( {side, setSide} ) => {
  
  function toggleElement( element ){
    setSide( prevSide => ({
      ...prevSide,
      [element]: !prevSide[element]
    }))
  }

  const setSpikes = ( event, value ) => {
    if( value !== null){
      setSide( prevSide => ({
        ...prevSide,
        spikes: parseInt(value)
  }))}}

  
  return(
    <div>
      <div className="LineBlock">
        <ToggleButton
          value="protected"
          color="standard"
          selected={side.isProtected}
          onChange={() => {
            toggleElement('isProtected');
          }}>
            Protect
        </ToggleButton>
      </div><div className="LineBlock">
        <ToggleButton
          value="stealth-rock"
          color="standard"
          selected={side.isSR}
          onChange={() => {
            toggleElement('isSR');
          }}>
            Stealth Rock
        </ToggleButton>
      </div><div className="LineBlock">
        <ToggleButtonGroup
          value={side.spikes}
          exclusive
          onChange={setSpikes}>
          <ToggleButton
            value={0}
            color="standard">
              0
          </ToggleButton>
          <ToggleButton
            value={1}
            color="standard">
              1
          </ToggleButton>
          <ToggleButton
            value={2}
            color="standard">
              2
          </ToggleButton>
          <ToggleButton
            value={3}
            color="standard">
              3 Spikes
          </ToggleButton>
        </ToggleButtonGroup>
      </div><div className="LineBlock">
        <ToggleButton
          size="normal"
          value="reflect"
          color="standard"
          selected={side.isReflect}
          onChange={() => {
            toggleElement('isReflect');
          }}>
            Reflect
        </ToggleButton>
        <ToggleButton
          size="normal"
          value="light-screen"
          color="standard"
          selected={side.isLightScreen}
          onChange={() => {
            toggleElement('isLightScreen');
          }}>
            Light Screen
        </ToggleButton>
        <ToggleButton
          size="normal"
          value="aurora-veil"
          color="standard"
          selected={side.isAuroraVeil}
          onChange={() => {
            toggleElement('isAuroraVeil');
          }}>
            Aurora Veil
        </ToggleButton>
      </div><div className="LineBlock">
        <ToggleButton
          value="battery"
          color="standard"
          selected={side.isHelpingHand}
          onChange={() => {
            toggleElement('isHelpingHand');
          }}>
            Helping Hand
        </ToggleButton>
      </div><div className="LineBlock">
        <ToggleButton
          value="friend-guard"
          color="standard"
          selected={side.isFriendGuard}
          onChange={() => {
            toggleElement('isFriendGuard');
          }}>
            Friend Guard
        </ToggleButton>
      </div><div className="LineBlock">
        <ToggleButton
          value="tailwind"
          color="standard"
          selected={side.isTailwind}
          onChange={() => {
            toggleElement('isTailwind');
          }}>
            Tailwind
        </ToggleButton>
      </div><div className="LineBlock">
        <ToggleButton
          value="foresight"
          color="standard"
          selected={side.isForesight}
          onChange={() => {
            toggleElement('isForesight');
          }}>
            Foresight
        </ToggleButton>
      </div><div className="LineBlock">
        <ToggleButton
          value="battery"
          color="standard"
          selected={side.isBattery}
          onChange={() => {
            toggleElement('isBattery');
          }}>
            Battery
        </ToggleButton>
      </div>
    </div>
  );}

export default Side;