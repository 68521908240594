import {useState, useEffect} from "react";
import "./DamagePanel.css";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import DamageCardDamageSlot from "./DamageCardDamageSlot";
import { calculate_damage, get_nature_boost, getDamageText, calculate_stat } from "./utils";
import SpreadOptimizationPlot from "./SpreadOptimizationPlot";
import ButtonIcon from "@mui/material/IconButton";
import { Save } from "@mui/icons-material";
import { v4 as uuidv4 } from 'uuid';
import { getMaxMoveName } from "@smogon/calc/dist/move";
import { Move, Generations } from "@smogon/calc";

const DamagePanel = ({p1, p2, setP1, setP2, field, setField, side1, side2, setDamageList}) => {



  const maxMoveName = (pokemon, id) => {
    let gen = Generations.get(8);
    let _move = new Move(gen, pokemon.moveset[id]);
    if(_move.category === "Status") return "Max Guard";
    return getMaxMoveName(_move.type, pokemon.species);
  }

  const atk_string = (pokemon, id) => {
    let gen = Generations.get(8);
    let _move = new Move(gen, pokemon.moveset[id]);
    let attackerPower = "";
    if(_move.category === "Physical"){

      if(pokemon.boosts.atk > 0){
        attackerPower = `+${pokemon.boosts.atk} `
      } else{
        if(pokemon.boosts.atk < 0) {
          attackerPower = `${pokemon.boosts.atk} `
        }
      }
  
      attackerPower = `${calculate_stat(pokemon, 'atk')}`;
      if( get_nature_boost(pokemon, 'atk') > 1.01 ){
        attackerPower += "+"
      } else {
        if( get_nature_boost(pokemon, 'atk') < 0.99 ){
          attackerPower += "-"
        }
      }
      attackerPower += ' Atk';
    }else{
  
      if(pokemon.boosts.spa > 0){
        attackerPower = `+${pokemon.boosts.spa} `
      } else{
        if(pokemon.boosts.spa < 0) {
          attackerPower = `${pokemon.boosts.spa} `
        }
      }
  
      attackerPower = `${calculate_stat(pokemon, 'spa')}`;
      if( get_nature_boost(pokemon, 'spa') > 1.01 ){
        attackerPower += "+"
      } else {
        if( get_nature_boost(pokemon, 'spa') < 0.99 ){
          attackerPower += "-"
        }
      }
      attackerPower += ' SpA';
    }

    if(pokemon.moveCrit[id]) attackerPower += " (Crit)";
    return attackerPower;
  }

  const def_string = (pokemon, move) => {
    let gen = Generations.get(8);
    let _move = new Move(gen, move);
    let defenderPower = "";

    if(_move.defensiveCategory === "Physical"){

      if(pokemon.boosts.def > 0){
        defenderPower += `+${pokemon.boosts.def} `
      } else{
        if(pokemon.boosts.def < 0) {
          defenderPower += `${pokemon.boosts.def} `
        }
      }
  
      defenderPower = `${calculate_stat(pokemon, 'def')}`;
      if( get_nature_boost(pokemon, 'def') > 1.01 ){
        defenderPower += "+"
      } else {
        if( get_nature_boost(pokemon, 'def') < 0.99 ){
          defenderPower += "-"
        }
      }
      defenderPower += ' Def';
    }else{
  
      if(pokemon.boosts.spd > 0){
        defenderPower = `+${pokemon.boosts.spd} `
      } else{
        if(pokemon.boosts.spd < 0) {
          defenderPower = `${pokemon.boosts.spd} `
        }
      }
  
      defenderPower = `${calculate_stat(pokemon, 'spd')}`;
      if( get_nature_boost(pokemon, 'spd') > 1.01 ){
        defenderPower += "+"
      } else {
        if( get_nature_boost(pokemon, 'spd') < 0.99 ){
          defenderPower += "-"
        }
      }
      defenderPower += ' SpD';
    }
  
    defenderPower += ` / ${ pokemon.isDynamaxed ? 2*pokemon.originalCurHP : pokemon.originalCurHP} Hp`
    return defenderPower
  }

  const addDamageEntry = () => {

    if(moveID < 4) {

      if(p1.moveset[ moveID % 4 ] === "" || p2.species === "none" || p1.species === "none")
        return;
      let result = calculate_damage(p1, p2, p1.moveset[ moveID % 4 ], {attackerSide: side1, defenderSide: side2, base: field}, p1.moveCrit[moveID%4]);
      if(result === 0) return;
      setDamageList( prevArray => [...prevArray, {
        id: uuidv4(),
        attackerName:      p1.name,
        attackerAbility:   p1.ability,
        attackerItem:      p1.item,
        attackerNature:    p1.nature,
        attackerAnimation: p1.animation,
        attackerMax:       p1.isDynamax,
        attackerAttack:    atk_string(p1, moveID % 4),
        defenderMax:       p2.isDynamax,
        defenderDefense:   def_string(p2, p1.moveset[moveID % 4]),
        defenderName:      p2.name,
        defenderAbility:   p2.ability,
        defenderItem:      p2.item,
        defenderNature:    p2.nature,
        defenderAnimation: p2.animation,
        moveName: p1.isDynamax ? maxMoveName(p1, moveID % 4) : p1.moveset[ moveID % 4 ],
        moveDamage: result.damage,
        moveText: damageText
      }])
    } else {
      if(p2.moveset[ moveID % 4 ] === "" || p1.species === "none" || p2.species === "none")
        return;
      let result = calculate_damage(p2, p1, p2.moveset[ moveID % 4 ], {attackerSide: side2, defenderSide: side1, base: field}, p2.moveCrit[moveID%4]);
      if(result === 0) return;
      setDamageList( prevArray => [...prevArray, {
        id: uuidv4(),
        attackerName:      p2.name,
        attackerAbility:   p2.ability,
        attackerItem:      p2.item,
        attackerNature:    p2.nature,
        attackerAnimation: p2.animation,
        attackerMax:       p2.isDynamax,
        attackerAttack:    atk_string(p2, moveID % 4),
        defenderMax:       p1.isDynamax,
        defenderDefense:   def_string(p1, p2.moveset[moveID % 4]),
        defenderName:      p1.name,
        defenderAbility:   p1.ability,
        defenderItem:      p1.item,
        defenderNature:    p1.nature,
        defenderAnimation: p1.animation,
        moveName: p2.isDynamax ? maxMoveName(p2, moveID % 4) : p2.moveset[ moveID % 4 ] ,
        moveDamage: result.damage,
        moveText: damageText
      }])
    }
  }

  const moveDictBase = {
    damage: [],
    move: "",
    crit: false,
    defender: {},
    attacker: {},
    field: {}
  };

  const [moveID, setMoveID] = useState(0);
  const [damageText, setDamageText] = useState("");


  const getAttacker = (id) => {
    if(id < 4){
      return setP1;
    } else {
      return setP2;
    }
  }

  const getDefender = (id) => {
    if(id < 4){
      return setP2;
    } else {
      return setP1;
    }
  }


  const getMoveDict = (id) => {
    let dict = moveDictBase;
    if(id < 4){
      if(p1.moveset[ id % 4 ] !== "" && p2.species !== "none" && p1.species !== "none"){
        let result = calculate_damage(p1, p2, p1.moveset[ id % 4 ], {attackerSide: side1, defenderSide: side2, base: field}, p1.moveCrit[id%4]);
        dict = {
          ...moveDictBase,
          damage: result.damage,
          move: p1.moveset[ id % 4 ],
          crit: p1.moveCrit[ id % 4 ],
          defender: p2,
          attacker: p1,
          field: {
            attackerSide: side1,
            defenderSide: side2,
            base: field
          }
        };
      }
    } else {
      if(p2.moveset[ id % 4 ] !== "" && p1.species !== "none" && p2.species !== "none"){
        let result = calculate_damage(p2, p1, p2.moveset[ id % 4 ], {attackerSide: side2, defenderSide: side1, base: field}, p2.moveCrit[id%4]);
        dict = {
          ...moveDictBase,
          damage: result.damage,
          move: p2.moveset[ id % 4 ],
          crit: p2.moveCrit[ id % 4 ],
          defender: p1,
          attacker: p2,
          field: {
            attackerSide: side2,
            defenderSide: side1,
            base: field
          }
        };
      }
    }
    return dict;
  };

  const updateKOChance = (id ) => {
    let dict = getMoveDict(id);
    let result = getDamageText( dict );
    setDamageText(result);
  }

  useEffect( () => {updateKOChance(moveID)}, [p1,p2,field,side1, side2] ); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGameTypeChange = (e, value) => {
    if(value !== null){
      setField( prevField => ({
        ...prevField,
        gameType: value
      }) )
    }
  }

  const handleTerrainChange = (e, value) => {
    setField( prevField => ({
      ...prevField,
      terrain: value
    }))
  }

  const handleWeatherChange = (e, value) => {
    setField( prevField => ({
      ...prevField,
      weather: value
    }))
  }


  const handleMoveIDChange = (e, newValue) => {
    if( newValue !== null){
      setMoveID(newValue);
      updateKOChance(newValue);
    }
  };

  return (
    <div className="DamagePanel">
      <div className="Field">
        
        <div className="FieldButtonList">
          <ToggleButtonGroup
            value={field.gameType}
            exclusive
            onChange={handleGameTypeChange}
          >
            <ToggleButton value={"Singles"}>
              Singles
            </ToggleButton>
            <ToggleButton value={"Doubles"}>
              Doubles
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div>
          <Typography> Weather: </Typography>
          <ToggleButtonGroup
            value={field.weather}
            exclusive
            onChange={handleWeatherChange}
          >
            <ToggleButton value={"Sun"} color="fire"> <img className="TerrainIcon" src="TerrainIcons/Fire_Type_Icon.png" alt="sun"></img> </ToggleButton>
            <ToggleButton value={"Rain"} color="water"> <img className="TerrainIcon" src="TerrainIcons/Water_Type_Icon.png" alt="sun"></img> </ToggleButton>
            <ToggleButton value={"Sand"} color="rock"> <img className="TerrainIcon" src="TerrainIcons/Rock_Type_Icon.png" alt="sun"></img> </ToggleButton>
            <ToggleButton value={"Hail"} color="ice"> <img className="TerrainIcon" src="TerrainIcons/Ice_Type_Icon.png" alt="sun"></img> </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="FieldButtonList">
          <Typography> Terrain: </Typography>
          <ToggleButtonGroup
            value={field.terrain}
            exclusive
            onChange={handleTerrainChange}
          >
            <ToggleButton value={"Grassy"} color="grass"> <img className="TerrainIcon" src="TerrainIcons/Grass_Type_Icon.png" alt="sun"></img> </ToggleButton>
            <ToggleButton value={"Psychic"} color="psychic"> <img className="TerrainIcon" src="TerrainIcons/Psychic_Type_Icon.png" alt="sun"></img> </ToggleButton>
            <ToggleButton value={"Electric"} color="electric"> <img className="TerrainIcon" src="TerrainIcons/Electric_Type_Icon.png" alt="sun"></img> </ToggleButton>
            <ToggleButton value={"Misty"} color="fairy"> <img className="TerrainIcon" src="TerrainIcons/Fairy_Type_Icon.png" alt="sun"></img> </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div className="DamageCard">
        <div className="DamageCardName"> {p1.name === "" ? p1.species : p1.name } </div>
        <div className="DamageCardMoves">
          <ToggleButtonGroup
            orientation="vertical"
            fullWidth
            value={moveID}
            role={"moves"}
            exclusive
            onChange={handleMoveIDChange}>
            { p1.moveset.map( (element, index) => {
              return(<ToggleButton  value={index} key={index}> {(element === "" || element === null)  ?  "No Move" : 
              p1.isDynamax ? maxMoveName(p1, index % 4) : element } </ToggleButton>);
            })}
          </ToggleButtonGroup>
        </div>
        <div className="DamagePanelDamages">
          { p1.moveset.map( (element, index) => {
            return(<DamageCardDamageSlot  key={index} p1={p1} p2={p2} move={element} field={{attackerSide: side1, defenderSide: side2, base: field}} crit={p1.moveCrit[index]}/>)
          })}
        </div>
      </div>
      <div className="DamageCard">
        <div className="DamageCardName"> {p2.name === "" ? p2.species : p2.name } </div>
        <div className="DamageCardMoves">
          <ToggleButtonGroup
            orientation="vertical"
            fullWidth
            value={moveID}
            role={"moves"}
            exclusive
            onChange={handleMoveIDChange}>
            { p2.moveset.map( (element, index) => {
              return(<ToggleButton value={4+index} key={4+index}> {(element === "" || element === null)  ? "No Move" :
              p2.isDynamax ? maxMoveName(p2, index % 4) : element } </ToggleButton>);
            })}
          </ToggleButtonGroup>
        </div>
        <div className="DamagePanelDamages">
          { p2.moveset.map( (element, index) => {
            return(<DamageCardDamageSlot  key={index} p1={p2} p2={p1} move={element} field={{attackerSide: side2, defenderSide: side1, base: field}} crit={p2.moveCrit[index]}/>)
          })}
        </div>
      </div>
      <div className="DamageText">
         <div className="DamageText_Text">{damageText}</div>
         <div className="DamageText_Button">
          <ButtonIcon
            disabled={ damageText === "Select a move to calculate KO chances"? true : false }
            onClick={addDamageEntry}
          >
            <Save />
          </ButtonIcon>
         </div>
        </div>
      <div className="SpreadOptimization">
        <SpreadOptimizationPlot moveDict={getMoveDict(moveID)} attacker={getAttacker(moveID)} defender={getDefender(moveID)} />
      </div>
    </div>
  );
}


export default DamagePanel;
