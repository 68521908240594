import React from "react";
import "./Logo.css";

const Logo = () => {
  return (
    <div className="Logo">
      <div className="LogoInner">
      <img className="LogoImg" alt="logo" src="slowkinglogo.png" ></img>
      <div className="LogoText">
        <div className="MainText">
          <span className="pink">Poke</span>
          <span className="grey">mon</span></div>
        <div className="SubText">
          <span className="pink">H</span>
          <span className="grey">ints and </span>
          <span className="pink">A</span>
          <span className="grey">dvanced </span>
          <span className="pink">T</span>
          <span className="grey">ools</span>
        </div>
        <span></span>
      </div>
      </div>
    </div>
  );
}

export default Logo;
