import {useState, useEffect} from "react";
import { calculate_stat, get_nature_boost, get_ev_list } from "./utils";
import "./StatElementSlider.css";
import {Slider} from "@mui/material"
import StatBump from "./StatBump";

const StatElementSlider = ({pokemon, setPokemon, statName, stat}) => {




  const [tempEV, setTempEV] = useState(pokemon.evs[stat]);
  useEffect( () => {
    setTempEV( pokemon.evs[stat] )
  }, [pokemon, stat] )



  const ev_list = get_ev_list();
  let marks = [];
  ev_list.forEach( element => marks.push( {value: element, label:""} ) )

  const changeRange = (e) => {
    setTempEV(e);
  };

  const changeIV = (_stat, e) => {

    let iv = e.target.value    
    if( e.target.value === "" || e.target.value === null || isNaN(e.target.value)) iv = 0;
    
    setPokemon( prevPokemon => ({
      ...prevPokemon,
      ivs: {
        ...prevPokemon.ivs,
        [_stat]: parseInt(iv)
      }      
    }));
    if( _stat === 'hp'){
      setPokemon( prevPokemon => ({
        ...prevPokemon,
        originalCurHP: calculate_stat(prevPokemon, 'hp')
      }));
    }
  };

  const changeEV = (_stat, e) => {

    let ev = e 
    if( e === "" || e === null || isNaN(e)) ev = 0;
    setPokemon( prevPokemon => ({
      ...prevPokemon,
      evs: {
        ...prevPokemon.evs,
        [_stat]: parseInt(ev)
      }      
    }));

    if(_stat === "hp"){
      setPokemon( prevPokemon => ({
        ...prevPokemon,
        originalCurHP: calculate_stat(prevPokemon, 'hp')
      }))    
    }

    setTempEV(parseInt(ev));
  };

  const changeStage = (_stat, e) => {
    setPokemon( prevPokemon => ({
      ...prevPokemon,
      boosts: {
        ...prevPokemon.boosts,
        [_stat]: parseInt(e)
      }
    }))
  };

  return(
    <li className="StatElementSlider">
      <div className={get_nature_boost(pokemon, stat) > 1.01 ? "ses_statname positive_boost" : get_nature_boost(pokemon, stat) < 0.99 ? "ses_statname negative_boost" : "ses_statname"} >{statName} </div>
      <div className="ses_statvalue">{pokemon.stats[stat]}</div>
      <input className="ses_iv" type={"number"} min="0" max="31" value={pokemon.ivs[stat]} onChange={(e) => {changeIV(stat, e)}}></input> 
      <input className={get_ev_list().includes(pokemon.evs[stat]) ? "ses_ev" : "ses_ev wrong_evs"} type={"number"} min="0" max="252" step="4" value={tempEV} onChange={(e) => {changeEV(stat, e.target.value)}}></input>
      <Slider className="ses_evslider" valueLabelDisplay="auto" step={null} marks={marks} size="small" value={tempEV} onChange={(e, newValue) => {changeRange(newValue)}} min={0} max={252} onChangeCommitted={(e, newValue) => {changeEV(stat, newValue)}}/>
      { stat !== 'hp' ?
        <select name="boost_stage" className="ses_stage" value={pokemon.boosts[stat]} onChange={(e)=>{changeStage(stat, e.target.value)}}>
          <option value={6}> +6 </option>
          <option value={5}> +5 </option>
          <option value={4}> +4 </option>
          <option value={3}> +3 </option>
          <option value={2}> +2 </option>
          <option value={1}> +1 </option>
          <option value={0}>  0 </option>
          <option value={-1}>-1 </option>
          <option value={-2}>-2 </option>
          <option value={-3}>-3 </option>
          <option value={-4}>-4 </option>
          <option value={-5}>-5 </option>
          <option value={-6}>-6 </option>
        </select> : null }
      <div className="ses_total"> {calculate_stat(pokemon, stat, tempEV)} </div>
      {get_nature_boost(pokemon, stat) > 1.01 ? 
      <StatBump className="BumpIcon" pokemon={pokemon} stat={stat} />: null}
    </li>
  );
}


export default StatElementSlider;