import React from "react";
import { calculate_damage, calculate_stat } from "./utils";
import "./DamageCardDamageSlot.css";

const DamageCardDamageSlot = ({p1, p2, move, field, crit}) => {

  const null_damage = [
    0,0,0,0,
    0,0,0,0,
    0,0,0,0,
    0,0,0,0]


  let result = {
    damage: null_damage
  };

  if((p1.species !== "none" && p2.species !== "none") && (move !== "" && move !== null ) ){
    result = calculate_damage(p1, p2, move, field, crit);
  } 

  if( result.damage === 0 ) result.damage = null_damage;

  let min_perc_damage = Math.floor( (1000.0*result.damage[0 ])/calculate_stat(p2, 'hp'))/10.0;
  let max_perc_damage = Math.floor( (1000.0*result.damage[result.damage.length-1])/calculate_stat(p2, 'hp'))/10.0;


  return (
    <div className="DamageCardDamageSlot">
      <div className="DamageSlotDamageContainer">
        <div className="DamageSlotMinDamage"> {min_perc_damage}% </div>
        <div className="DamageSlotHyphen"> - </div>
        <div className="DamageSlotMaxDamage"> {max_perc_damage}% </div>
      </div>
    </div>
  );
}

export default DamageCardDamageSlot;