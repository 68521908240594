import React from "react";
import "./PokemonSlotDisplay.css";

const PokemonSlotDisplay = ({pokemon}) => {


  return (
    <div className="PokemonSlotDisplay">
      <img alt="pokemon" src={`${pokemon.animation}`}></img>
    </div>
  );

}

export default PokemonSlotDisplay;