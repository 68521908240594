import {useState} from "react";
import "./SearchButton.css"
import {Autocomplete, TextField} from "@mui/material";
import {get_move_list, get_pokemon_list, calculate_stat } from "./utils";
import { Dex } from "@pkmn/dex";
import { v4 as uuidv4 } from 'uuid';
import { parse_showdown_pokemon } from "./utils";
/*
import { Generations } from "@pkmn/data";
import * as calc from "@smogon/calc";
*/

const SearchButton = ({pokemon, setPokemon, pokemonBase, setPokemonBox}) => {

  const [searchText, setSearchText] = useState({species: " ", name: "", sd_str: ""});
  const all_moves = get_move_list(searchText);
  /*
  async function get_learnset(pokemon_name){
    const gens = new Generations(Dex);
    return await gens.get(8).learnsets.get(pokemon_name)
  }*/

  const handleSearchText = (e, newValue) => {
    if( newValue.name === null || newValue.name === undefined || newValue === null) return;
    setSearchText(newValue);
  }

  const pokemon_list = get_pokemon_list();

  const searchPokemon = (e, pokemonName) => {
    if( pokemonName.name === "" || pokemonName.name === null || pokemonName.name === undefined ){
      setPokemon({...pokemonBase});
    }
    else{

      if(pokemon.uuid !== "" ) setPokemonBox( prevBox => [...prevBox, pokemon] )

      if( pokemonName.sd_str === ""){
        let pk = JSON.parse(JSON.stringify(pokemonBase));
        const smogon_pk = Dex.forGen(8).species.get(pokemonName.name);
        let _abilities = Object.values(smogon_pk.abilities);
        let _moves = all_moves;
        pk.stats = smogon_pk.baseStats;
        pk.species = smogon_pk.name;
        pk.name = smogon_pk.name;
        pk.animation = `ani/${pk.species.toLowerCase()}.gif`;
        pk.animation = pk.animation.replace('-gmax', "")
        pk.abilities = _abilities;
        pk.moves = _moves;
        pk.uuid = uuidv4();
        pk.originalCurHP = calculate_stat(pk, 'hp');
        setPokemon({...pk});
      }
      else{
        let pk = parse_showdown_pokemon(pokemonName.sd_str, JSON.parse(  JSON.stringify(pokemonBase)));
        setPokemon({...pk});

      }
    }

    setSearchText({species: " ", name: "", sd_str: ""})
  }


  return (
    <div className="SearchButton">
      <Autocomplete 
        options={pokemon_list.sort((a,b) => -b.species.localeCompare(a.species))}
        autoSelect
        renderInput={(params) => <TextField {...params} label={"Pokemons"} sx={{width: '256px', margin:'5px 0px 0px 5px'}} size="small" margin="none"/>}
        groupBy={(option) => option.species}
        getOptionLabel={(option) => option.name || ""}
        isOptionEqualToValue={(option, value) => option.name === value.name || value === ""}
        onChange={(e, newValue) => {
            handleSearchText(e, newValue);
            searchPokemon(e, newValue);
        }}
        value={searchText.name}
      />
      <button
        onClick={()=>{searchPokemon("", searchText)}}
        id="pokemon_search_button">
        <img
          alt="q"
          src="search/search.png" ></img>
      </button>
        
    </div>
  );
}


export default SearchButton;
