import React, { useEffect, useState } from "react";
import Plot from 'react-plotly.js';
import { derive_damage_mesh, get_ev_list } from "./utils";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import "./SpreadOptimizationPlot.css";

const SpreadOptimizationPlot = ({moveDict, attacker, defender}) => {
  
  const [x, setX] = useState('hp');
  const [y, setY] = useState('def');

  const get_spread_point = (stat) => {
    if(moveDict.move === "" ) return 0;
    if(moveDict.attacker.evs === undefined ) return 0;
    if(moveDict.defender.evs === undefined ) return 0;
    
    if( stat === 'atk' || stat === 'spa')
    {
      return moveDict.attacker.evs[stat];
    } else {
      return moveDict.defender.evs[stat];
    }
  }

  const get_axis_title = (stat) => {
    if( stat === 'atk' || stat === 'spa')
    {
      return `${moveDict.attacker.name} ${stat}`
    } else {
      return `${moveDict.defender.name} ${stat}`
    }
  }

  const updateAxis = (axis, value) => {
    if( axis === "x"){
      setX(value);
      setXValue( get_spread_point(x) );
    } else {
      setY(value);
      setYValue( get_spread_point(y) );
    }
  }

  const [xValue, setXValue] = useState(get_spread_point(x));
  const [yValue, setYValue] = useState(get_spread_point(y));


  const approxValue = (num) => {
    let _x = Math.floor( num );
    
    if (_x < 4) return 0;
    let _t = (_x-4)%8
    if(_t > 4) {
      _x += (8-_t);
    } else {
      _x -= _t;
    }
    _x = Math.max(0, _x);
    _x = Math.min(252, _x);

    return _x;

  }

  const updateSpread = (e) => {


    if( !('shapes[0].xanchor' in e) ) return;


    //let _y = approxValue((e['shapes[0].y1']+e['shapes[0].y0'])/2.0 );
    //let _x = approxValue((e['shapes[0].x1']+e['shapes[0].x0'])/2.0 );
    let _x = approxValue((e['shapes[0].xanchor']));
    let _y = approxValue((e['shapes[0].yanchor']));
    setXValue(_x);
    setYValue(_y);

    if( x === 'atk' || x === 'spa')
    {
      attacker( prevPk => ({
        ...prevPk,
        evs: {
          ...prevPk.evs,
          [x]: _x
        }
      }));
    } else {
      defender( prevPk => ({
        ...prevPk,
        evs: {
          ...prevPk.evs,
          [x]: _x
        }
      }));
    }

    if( y === 'atk' || y === 'spa')
    {
      attacker( prevPk => ({
        ...prevPk,
        evs: {
          ...prevPk.evs,
          [y]: _y
        }
      }));
    } else {
      defender( prevPk => ({
        ...prevPk,
        evs: {
          ...prevPk.evs,
          [y]: _y
        }
      }));
    }

    
  }

  useEffect( () => { 
    
    
    function update_spread_point(stat){
      if( moveDict.attacker.evs === undefined ) return 0;
      if( moveDict.defender.evs === undefined ) return 0;
      

      if( stat === 'atk' || stat === 'spa')
      {
        return moveDict.attacker.evs[stat];
      } else {
        return moveDict.defender.evs[stat];
      }
    }

    setXValue( update_spread_point(x) );
    setYValue( update_spread_point(y) );
  }, [moveDict.attacker.evs, moveDict.defender.evs, x, y]  )


  return (
    <div>
      <div className="SelectAxisButton">
        <FormControl fullWidth>
          <InputLabel id="x_axis_label">X Axis</InputLabel>
          <Select
            labelId="x_axis_label"
            label="X Axis"
            value={x}
            onChange={(newAxis) => {updateAxis('x', newAxis.target.value)}}
            >
              <MenuItem value={'hp'}>HP</MenuItem>
              <MenuItem value={'atk'}>Attack</MenuItem>
              <MenuItem value={'spa'}>Special Attack</MenuItem>
              <MenuItem value={'def'}>Defence</MenuItem>
              <MenuItem value={'spd'}>Special Defence</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="SelectAxisButton">
        <FormControl fullWidth>
          <InputLabel id="y_axis_label">Y Axis</InputLabel>
          <Select
            labelId="y_axis_label"
            label="Y Axis"
            value={y}
            onChange={(newAxis) => {updateAxis('y', newAxis.target.value)}}
            >
              <MenuItem value={'hp'}>HP</MenuItem>
              <MenuItem value={'atk'}>Attack</MenuItem>
              <MenuItem value={'spa'}>Special Attack</MenuItem>
              <MenuItem value={'def'}>Defence</MenuItem>
              <MenuItem value={'spd'}>Special Defence</MenuItem>
          </Select>
        </FormControl>
      </div>
    { ((moveDict.move !== "" ) &&
      ( moveDict.move !== null) &&
      ( moveDict.move !== undefined) && 
      ( moveDict.damage.length !== 0) && 
      ( moveDict.defender !== {}) && 
      ( moveDict.attacker !== {})) &&
      ( moveDict.attacker !== undefined) && 
      ( moveDict.defender !== undefined)      
      ?
      <Plot className="SpreadOptimizationPlot"

        onRelayout={updateSpread}
        data={[
          {
            x: get_ev_list(),
            y: get_ev_list(),
            z: derive_damage_mesh(moveDict, x, y),
            type: 'contour',  
            colorscale: "Viridis",
            contours: {
              showlabels: true,
              
              coloring: "lines",
              labelfont: {
                family: 'Raleway',
                size: 18,
              }
            },
            showscale: false,
            colorbar: {
              title: "Damage (%)",
              side: 'bottom'
            },
            line: {
              smoothing: 0.0,
              width: 2
            },
          },
          {
            x: [get_spread_point(x)],
            y: [get_spread_point(y)],
            type: 'scatter',
            mode: 'line',
          },
        ]}

        config={{displayModeBar: false, edits:{shapePosition: true, annotationPosition:false}}}

        layout = {{
          shapes: [
            {
              type: 'circle',
              x0: -40,
              y0: -40,
              x1: +40,
              y1: +40,
              xsizemode: 'pixel',
              ysizemode: 'pixel',
              xanchor: xValue,
              yanchor: yValue,
              xref: 'x',
              yref: 'y',
              fillcolor: 'red',
              line: {
                color: 'red',
                width: 0
              },
              opacity: 0.5,
              name: 'CircleShape',
            }
          ],/*
          annotations: [{
            x: xValue,
            y: yValue,
            xref: 'x',
            yref: 'y',
            text: `Current: (${x}:${xValue},${y}:${yValue})`,
            showarrow: true,
            arrowhead: 7,
            ax: 0,
            ay: -40
          }],*/
          margin:{
            l: 10,
            r: 10,
            b: 10,
            t: 30,
          },
          autosize: true,
          title: `${moveDict.attacker.name} ${moveDict.move} on ${moveDict.defender.name} ${moveDict.crit? '(Crit)' : ''}`,
          titlefont: {
            size: 14
          },
          xaxis: {
            fixedrange: true,
            range: [-6, 258],
            title: get_axis_title(x),
            tickmode: 'linear',
            automargin: true,
            tick0: 4,
            dtick: 32,
            nticks: 8,
            mirror: "ticks",
            showgrid: true,
            showline: true,
            zeroline: false
          },
          yaxis: {
            fixedrange: true,
            showgrid: true,
            showline: true,
            zeroline: false,
            automargin: true,
            mirror: "ticks",
            range: [-6, 258],
            title: get_axis_title(y),
            tickmode: 'linear',
            tick0: 4,
            dtick: 32,
            nticks: 8,
          },
        }}

        useResizeHandler
      
      /> : "" }
    </div>
  );
}


export default SpreadOptimizationPlot;