
export const swsh_sets = () => {
  let sets = [

    /* =========================================================================
      Sword Shield
    */ //=======================================================================

    //---------------------------------------------- Rinya Sun
    {
      name: "RinyaSun Incineroar - SwSh S12",
      species: "Incineroar",
      sd_str:`RinyaSun Incineroar - SwSh S12 (Incineroar) @ Figy Berry  
      Ability: Intimidate  
      Level: 50  
      EVs: 252 HP / 4 Atk / 156 Def / 68 SpD / 28 Spe  
      Impish Nature  
      - Flare Blitz  
      - Darkest Lariat  
      - Parting Shot  
      - Fake Out`
    },

    {
      name: "RinyaSun AV Groudon - SwSh S12",
      species: "Groudon",
      sd_str: `RinyaSun AV Groudon - SwSh S12 (Groudon) @ Assault Vest  
      Ability: Drought  
      Level: 50  
      Shiny: Yes  
      EVs: 164 HP / 204 Atk / 4 Def / 108 SpD / 28 Spe  
      Adamant Nature  
      - Precipice Blades  
      - Stone Edge  
      - Heat Crash  
      - Heavy Slam `
    },

    {
      name: "RinyaSun Zacian - SwSh S12",
      species: "Zacian",
      sd_str: `RinyaSun Zacian - SwSh S12 (Zacian-Crowned) @ Rusted Sword  
      Ability: Intrepid Sword  
      Level: 50  
      Shiny: Yes  
      EVs: 252 Atk / 4 Def / 252 Spe  
      Jolly Nature  
      - Behemoth Blade  
      - Sacred Sword  
      - Play Rough  
      - Protect `
    },

    {
      name: "RinyaSun Lum Charizard - SwSh S12",
      species: "Charizard",
      sd_str: `RinyaSun Lum Charizard (Charizard-Gmax) @ Lum Berry  
      Ability: Solar Power  
      Level: 50  
      Shiny: Yes  
      EVs: 252 SpA / 4 SpD / 252 Spe  
      Timid Nature  
      IVs: 0 Atk  
      - Blast Burn  
      - Hurricane  
      - Heat Wave  
      - Protect `
    },

    {
      name: "RinyaSun Grimmsnarl - SwSh S12",
      species: "Grimmsnarl",
      sd_str: `"RinyaSun Grimmsnarl - SwSh S12 (Grimmsnarl-Gmax) (M) @ Iron Ball  
      Ability: Prankster  
      Level: 50  
      EVs: 164 HP / 188 Def / 156 SpD  
      Impish Nature  
      IVs: 0 Atk  
      - Foul Play  
      - Trick  
      - Thunder Wave  
      - Reflect`
    },

    {
      name: "RinyaSun Gastrodon - SwSh S12",
      species: "Gastrodon",
      sd_str: `RinyaSun Gastrodon - SwSh S12 (Gastrodon) @ Leftovers  
      Ability: Storm Drain  
      Level: 50  
      EVs: 180 HP / 204 Def / 124 SpD  
      Bold Nature  
      IVs: 0 Atk  
      - Earth Power  
      - Ice Beam  
      - Yawn  
      - Protect`
    }

  ]
  
  
  
  return sets
}