import React from "react";
import "./BoxCard.css"
import ButtonIcon from "@mui/material/IconButton"
import CancelIcon from '@mui/icons-material/Cancel';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
const BoxCard = ({pokemon, setPokemonBox, pokemonSlot1, setPokemonSlot1, pokemonSlot2, setPokemonSlot2}) => {

  const cancelFromBox = () => {
    setPokemonBox( prevBox => prevBox.filter( (pk) => { return pk.uuid !== pokemon.uuid} ) )
  }

  const swapPokemon = (slot, setSlot) => {
    if(slot.uuid !== "") setPokemonBox( prevBox => [...prevBox, slot] );
    setSlot( pokemon );
    cancelFromBox()
  }


  return(
    <div className="BoxCard">
      <div className="Buttons">
        <ButtonIcon className="ToSlot2Button" size="small" onClick={()=>{swapPokemon(pokemonSlot1, setPokemonSlot1)}}>
          <RadioButtonUncheckedIcon></RadioButtonUncheckedIcon>
        </ButtonIcon>
        <ButtonIcon className="ToSlot1Button" size="small" onClick={()=>{swapPokemon(pokemonSlot2, setPokemonSlot2)}}>
          <RadioButtonUncheckedIcon></RadioButtonUncheckedIcon>
        </ButtonIcon>
        <ButtonIcon className="CancelButton" size="small" onClick={cancelFromBox}>
          <CancelIcon color="fire" />
        </ButtonIcon>
      </div>
      <div className="PokemonAnimation"><img  alt={pokemon.name} src={pokemon.animation}></img></div>
      <div className="PokemonName"> <strong>{pokemon.name}</strong></div>
    </div>
  );

}

export default BoxCard;