import React from "react"
import "./PokemonBuilder.css"
import StatElementSlider from "./StatElementSlider"
import PokemonMoveSelection from "./PokemonMoveSelection"
import { calculate_stat, get_item_list } from "./utils"
import { TextField, Typography, Select, MenuItem, Autocomplete, FormControlLabel, Checkbox } from "@mui/material";

const PokemonBuilder = ({pokemon, setPokemon}) => {

  const total_ev = () => {
    let sum = 0;
    for(let _i in pokemon.evs){
      sum += pokemon.evs[_i]
    }
    return sum;
  }

  const item_list = get_item_list();
  const changeLevel = (e) => {

    let lv = e.target.value    
    if( e.target.value === "" || e.target.value === null || isNaN(e.target.value)) lv = 0;
    setPokemon( prevPokemon => ({
      ...prevPokemon,
      level: parseInt(lv),
    }) );
    setPokemon( prevPokemon => ({
      ...prevPokemon,
      originalCurHP:  calculate_stat(prevPokemon, 'hp')
    }) );
  };

  const changeNature = (e) => {
    setPokemon( prevPokemon => ({
      ...prevPokemon,
      nature: e.target.value
    }) );
  };

  const changeAbility = (e) => {
    setPokemon( prevPokemon => ({
      ...prevPokemon,
      ability: e.target.value
    }) );
  };

  const changeDynamax = (e) => {
    setPokemon( prevPokemon => ({
      ...prevPokemon,
      isDynamax: e.target.checked,
    }));
    setPokemon( prevPokemon => ({
      ...prevPokemon,
      originalCurHP: calculate_stat(prevPokemon, 'hp')
    }));
  }

  const changeItem = (e, newItem) => {
    setPokemon( prevPokemon => ({
      ...prevPokemon,
      item: newItem
    }) );
  };

  const changeName = (e) => {
    setPokemon( prevPokemon => ({
      ...prevPokemon,
      name: e.target.value
    }))
  }

  return(
    <div className="PokemonBuilder">

      <div className="PokemonBuilder_Grid">
        <Typography
          className="PokemonBuilder_PokemonSpecies"  
        > {pokemon.species} </Typography>
        <TextField 
          className="PokemonBuilder_PokemonName" 
          label="Name"
          size="small"
          value={pokemon.name}
          onChange={ changeName }
        ></TextField>
        <Typography
          className="PokemonBuilder_PokemonLevelLabel"  
        > Level: </Typography>
        <TextField
          className="PokemonBuilder_PokemonLevel" 
          type="number"
          size="small"
          value={pokemon.level}
          onChange={changeLevel}
        ></TextField> 
        <Typography
          className="PokemonBuilder_PokemonAbilityLabel"  
        > Ability: </Typography>
        <Select
          size="small"
          className="PokemonBuilder_PokemonAbility"
          value={pokemon.ability} 
          onChange={changeAbility}
        >
          {pokemon.abilities.map(item => <MenuItem key={item} value={item}> {item} </MenuItem>)}
        </Select>
        <Typography
          className="PokemonBuilder_PokemonItemLabel"  
        > Item: </Typography>
        <Autocomplete
          size="small"
          className="PokemonBuilder_PokemonItem"
          autoComplete
          options={item_list}
          value={pokemon.item}
          onChange={changeItem}
          renderInput={(params) => <TextField {...params} label="Item" />}
        ></Autocomplete>
        <Typography
          className="PokemonBuilder_PokemonNatureLabel"  
        > Nature: </Typography>
        <Select
          size="small"
          className="PokemonBuilder_PokemonNature"
          value={pokemon.nature} 
          onChange={changeNature}
        >
          <MenuItem value="Serious">Serious             </MenuItem>
          <MenuItem value="Lonely"> Lonely  |+Atk|-Def  </MenuItem>
          <MenuItem value="Brave">  Brave   |+Atk|-Spe  </MenuItem>
          <MenuItem value="Adamant">Adamant |+Atk|-SpA  </MenuItem>
          <MenuItem value="Naughty">Naughty |+Atk|-SpD  </MenuItem>
          <MenuItem value="Bold">Bold |+Def|-Atk        </MenuItem>
          <MenuItem value="Relaxed">Relaxed |+Def|-Spe  </MenuItem>
          <MenuItem value="Impish">Impish |+Def|-SpA    </MenuItem>
          <MenuItem value="Lax">Lax |+Def|-SpD          </MenuItem>
          <MenuItem value="Timid">Timid |+Spe|-Atk      </MenuItem>
          <MenuItem value="Hasty">Hasty |+Spe|-Def      </MenuItem>
          <MenuItem value="Jolly">Jolly |+Spe|-SpA      </MenuItem>
          <MenuItem value="Naive">Naive |+Spe|-SpD      </MenuItem>
          <MenuItem value="Modest">Modest |+SpA|-Atk    </MenuItem>
          <MenuItem value="Mild">Mild |+SpA|-Def        </MenuItem>
          <MenuItem value="Quiet">Quiet |+SpA|-Spe      </MenuItem>
          <MenuItem value="Rash">Rash |+SpA|-SpD        </MenuItem>
          <MenuItem value="Calm">Calm |+SpD|-Atk        </MenuItem>
          <MenuItem value="Gentle">Gentle |+SpD|-Def    </MenuItem>
          <MenuItem value="Sassy">Sassy |+SpD|-Spe      </MenuItem>
          <MenuItem value="Carefull">Carefull |+SpD|-SpA</MenuItem>
        </Select>
        
      </div>

      <ul className="StatList">
        <StatElementSlider pokemon={pokemon} setPokemon={setPokemon} stat={"hp"} statName={"HP"}/>
        <StatElementSlider pokemon={pokemon} setPokemon={setPokemon} stat={"atk"} statName={"Atk"}/>
        <StatElementSlider pokemon={pokemon} setPokemon={setPokemon} stat={"def"} statName={"Def"}/>
        <StatElementSlider pokemon={pokemon} setPokemon={setPokemon} stat={"spa"} statName={"SpA"}/>
        <StatElementSlider pokemon={pokemon} setPokemon={setPokemon} stat={"spd"} statName={"SpD"}/>
        <StatElementSlider pokemon={pokemon} setPokemon={setPokemon} stat={"spe"} statName={"Spe"}/>
      </ul>
      <div className="TotalEV">
        <Typography display='inline' > Total Evs: </Typography>
        <Typography display='inline' className={total_ev() > 510 ? 'red' : null } > {`${total_ev()}`} </Typography>
      </div>
      <div className="Moveset">
        <PokemonMoveSelection pokemon={pokemon} setPokemon={setPokemon} move_id={0} />
        <PokemonMoveSelection pokemon={pokemon} setPokemon={setPokemon} move_id={1} />
        <PokemonMoveSelection pokemon={pokemon} setPokemon={setPokemon} move_id={2} />
        <PokemonMoveSelection pokemon={pokemon} setPokemon={setPokemon} move_id={3} />
      </div>
      <div className="Dynamax" >
        <FormControlLabel control={<Checkbox checked={pokemon.isDynamax} onChange={changeDynamax} />} label="Dynamax" />
      </div>
    </div>
  );
}


export default PokemonBuilder;