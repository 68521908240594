import React from "react";
import Plot from 'react-plotly.js';
import { get_ev_list, get_hp_residuals } from "./utils";

const DotOptimizerPlot = ({pokemon}) => {

  const ev_list = get_ev_list();
  const dot_1 = get_hp_residuals(pokemon, 0.25);
  const dot_2 = get_hp_residuals(pokemon, 1.0/16.0);
  const dot_3 = get_hp_residuals(pokemon, 0.1);
  const dot_4 = get_hp_residuals(pokemon, 1.0/6.0);

  return (
    <Plot
      data={[
        {
          x: ev_list,
          y: dot_1,
          type: 'scatter',
          mode: 'lines+markers',
          marker: {color: 'red'},
          name: "1/4 Dots"
        },
        {
          x: ev_list,
          y: dot_2,
          type: 'scatter',
          mode: 'lines+markers',
          marker: {color: 'blue'},
          name: "1/16 Dots"
        },
        {
          x: ev_list,
          y: dot_3,
          type: 'scatter',
          mode: 'lines+markers',
          marker: {color: 'green'},
          name: "1/10 Dots"
        },
        {
          x: ev_list,
          y: dot_4,
          type: 'scatter',
          mode: 'lines+markers',
          marker: {color: 'lightblue'},
          name: "1/6 Dots"
        }
      ]}
      layout={ {
        width: 300, 
        height: 300,
        margin:{
          l: 20,
          r: 20,
          b: 20,
          t: 20,
        },
        yaxis: {
          range: [0,20],
          automargin: true,
          title:"HP Residuals",
          showgrid: true,
          showline: true,
          zeroline: false,
          mirror: "ticks",
          tickmode: 'linear',
          dtick: 1,
        },
        xaxis: {
          automargin: true,
          title:"HP EV",
          range: [-4, 256],
          tickmode: 'linear',
          tick0: 4,
          dtick: 32,
          nticks: 8,
          mirror: "ticks",
          showgrid: true,
          showline: true,
          zeroline: false
        },
        legend: {
          x: 1,
          xanchor: 'right',
          y: 1
        }
      }}
      config={{displayModeBar: false}}
    />
  );
}

export default DotOptimizerPlot;