import { Pokemon, Generations, calculate, Move, Field } from "@smogon/calc";
import { getKOChance } from "@smogon/calc/dist/desc";
import { Dex } from "@pkmn/dex";
import { v4 as uuidv4 } from 'uuid';
import { getMaxMoveName } from "@smogon/calc/dist/move";
import { swsh_sets} from './swsh_sets'

export function get_hp_residuals(pokemon, dot_perc){
  let ev_list = get_ev_list();
  let hp_list = get_stat_list(pokemon, 'hp');

  let residuals = []
  for(let i in ev_list){
    let dot_damage = Math.floor( hp_list[i]*dot_perc );
    let res =  hp_list[i] % dot_damage;
    residuals.push(res);
  }

  return residuals;
}


export function parse_showdown_pokemon(text, dict){
  if(text === "") return dict;

  let lines = text.split('\n')
  let moves = []

  for(var line in lines){

    lines[line] = lines[line].trim()

    dict['uuid'] = uuidv4();

    if( parseInt(line) === 0 )
      process_first_line(lines[line], dict);


    
    const smogon_pk = Dex.forGen(8).species.get(dict['species']);
    dict.stats = smogon_pk.baseStats;
    dict.originalCurHP = calculate_stat(dict, 'hp');
    let _abilities = Object.values(smogon_pk.abilities);
    dict['abilities'] = _abilities;
    dict['moves'] = get_move_list(smogon_pk.species);
    if( lines[line].includes("Ability") )
      dict['ability'] = lines[line].replace('Ability: ', '').trim();
    
    if( lines[line].includes("Level") && !lines[line].includes("Dynamax") )
      dict['level'] = parseInt( lines[line].replace('Level: ', '').trim() );
    
    
    if( lines[line].includes("EVs") )
      dict['evs'] = parse_evs(lines[line]);
      
    if( lines[line].includes("IVs") )
      dict['ivs'] = parse_ivs(lines[line]);     

    if( lines[line].includes("Nature") )
      dict['nature'] = lines[line].replace(' Nature', '').trim();

    if( lines[line].trim().startsWith("- ") )
      moves.push( lines[line].replace('- ', '').trim() );
    
  }

  while(moves.length < 4){
    moves.push(null);
  }

  dict['moveset'] = moves;
  return dict;
}


export function parse_evs(line){
  line = line.replace('EVs: ', '')
  let evs = line.split( ' / ' )
  let dict = get_empty_stat_dict(0)
  for( let ev in evs){
    let arr = evs[ev].split(' ')
    let value = parseInt( arr[0] )
    let stat = arr[1].trim().toLowerCase()

    dict[stat] = value
  }

  return dict
}

export function parse_ivs(line){
  line = line.replace('IVs: ', '')
  let evs = line.split( ' / ' )
  let dict = get_empty_stat_dict(31)
  for( let ev in evs){
    let arr = evs[ev].split(' ')
    let value = parseInt( arr[0] )
    let stat = arr[1].trim().toLowerCase()

    dict[stat] = value
  }

  return dict
}


export function get_empty_stat_dict(value=0){
  return {
    'hp':  value,
    'atk': value,
    'def': value,
    'spa': value,
    'spd': value,
    'spe': value      
  };
}


export function process_first_line(line, dict){

  let array1 = line.split(' @ ')
  
  if( array1.length > 1 )
    dict['item'] =  array1[1].trim()
  

  if( array1[0].includes("(M)")){
    dict['gender']  = 'M';
    array1[0] = array1[0].replace('(M)','')
  }

  if( array1[0].includes("(F)")){
    dict['gender']  = 'F';
    array1[0] = array1[0].replace('(F)','')
  }

  if( array1[0].includes("(")){
    let _species = array1[0].split('(')[1].trim().replace(')','')
    dict['species']  = _species;
    dict['name']    = array1[0].split('(')[0].trim();
    dict['animation'] = `ani/${_species.toLowerCase()}.gif`;
  } else {
    dict['name']    = array1[0].trim();
    dict['species']    = dict['name'];
    dict['animation']    = `ani/${dict['name'].toLowerCase()}.gif`;
  }
  dict['animation'] = dict['animation'].replace('-gmax', '')
}



export function get_item_list() {
  let items = [""];
  const item_list = Dex.forGen(8).items.all();
  for( let i=0; i<item_list.length; i++ ){
    if(item_list[i].isNonstandard === null)
      items.push(item_list[i].name);
  }
  return items;
}

export function get_abilities(pokemon_name){
  if(pokemon_name === "")
    return [""];

  return Object.values(Dex.forGen(8).species.get(pokemon_name).abilities);
}


export function get_move_list(pokemon_name){
  const dex = Dex.forGen(8).moves.all();
  let pk_list = [""];

  for(let i=0; i<dex.length; i++){
    if(dex[i].isNonstandard === null){
      pk_list.push( dex[i].name )
    }
  }
  return pk_list;
}

export function get_pokemon_list(){
  let sets = swsh_sets()
  let pk_list = [{species: " ", name: "", sd_str: ""}];
  pk_list = pk_list.concat(sets)


  const dex = Dex.forGen(8).species.all();
  for(let i=0; i<dex.length; i++){
    if(
      dex[i].tier !== "Illegal" && 
      dex[i].tier !== "CAP" && 
      dex[i].tier !== "CAP LC" && 
      dex[i].tier !== "CAP NFE"){
      pk_list.push( 
        {
          species: dex[i].baseSpecies,
          name: dex[i].name,
          sd_str: ""
        } )
    }
  }
  return pk_list;
}


export function derive_damage_mesh(dict, x_axis, y_axis){

  let ev_list = get_ev_list();
  let damage_list = Array(ev_list.length).fill(null).map(() => Array(ev_list.length));
  for( let i=0; i<ev_list.length; i++ ){
    for( let j=0; j<ev_list.length; j++ ){
      damage_list[i][j] = 0.0;
    }
  }

  if(dict === null) return damage_list;
  if(dict === {}) return damage_list;
  if(dict.move === "") return damage_list;
  if(dict.move === null) return damage_list;
  if(dict.move === undefined) return damage_list;
  if(dict.attacker === {}) return damage_list;
  if(dict.attacker === null) return damage_list;
  if(dict.attacker === undefined) return damage_list;
  if(dict.defender === null) return damage_list;
  if(dict.defender === {}) return damage_list;
  if(dict.defender === undefined) return damage_list;

  let dict_cp = JSON.parse( JSON.stringify(dict) );
  let attacker_evs = dict_cp.attacker.evs;
  let defender_evs = dict_cp.defender.evs;
  let gen = Generations.get(8);

  /* MODIFI EVS */
  for(let i=0; i<ev_list.length; i++){
    
    if( x_axis === "atk" || x_axis === "spa" ){
      attacker_evs[x_axis] = ev_list[i];
    } else {
      defender_evs[x_axis] = ev_list[i];
    }

    for(let j=0; j<ev_list.length; j++){
      if( y_axis === "atk" || y_axis === "spa" ){
        attacker_evs[y_axis] = ev_list[j];
      } else {
        defender_evs[y_axis] = ev_list[j];
      }

      let atk_pk = getSmogonPokemon(gen, dict_cp.attacker, attacker_evs);
      let def_pk = getSmogonPokemon(gen, dict_cp.defender, defender_evs);
      let move = new Move(gen, dict_cp.move);
      move.useMax = atk_pk.isDynamaxed
      move.isCrit = dict.crit;
      let field = getSmogonField(dict_cp.field)
      let result = calculate(gen, atk_pk, def_pk, move, field);
      if( result.damage !== null ){
        let max_damage = result.damage[ result.damage.length-1 ];
        damage_list[j][i] = Math.floor((1000.0*max_damage)/(def_pk.isDynamaxed ? 2.0*def_pk.stats.hp : def_pk.stats.hp))/10.0;
      }
    }
  }
  return damage_list;
};

export function getSmogonField(dict){
  return new Field({
    attackerSide: dict.attackerSide,
    defenderSide: dict.defenderSide,
    gameType: dict.base.gameType,
    isGravity: dict.base.isGravity,
    terrain: dict.base.terrain,
    weather: dict.base.weather
  })
}


export function getDamageText(dict){

  const init_text = "Select a move to calculate KO chances";


  if( dict.move === "" )           return init_text;
  if( dict.move === null )         return init_text;
  if( dict.move === undefined )    return init_text;
  if( dict.damage === null)        return init_text;
  if( dict.damage === 0)           return init_text;
  if( dict.damage.length === 0)    return init_text;
  if( dict.defender === {})        return init_text;
  if( dict.defender === undefined) return init_text;
  if( dict.defender === null)      return init_text;
  if( dict.attacker === {})        return init_text;
  if( dict.attacker === undefined) return init_text;
  if( dict.attacker === null)      return init_text;

  let gen = Generations.get(8);
  let _defender = getSmogonPokemon(gen, dict.defender);
  let _attacker = getSmogonPokemon(gen, dict.attacker);
  let _move = new Move(gen, dict.move);
  _move.useMax = _attacker.isDynamaxed;
  _move.isCrit = dict.crit;
  let _field = getSmogonField(dict.field);

  let result = getKOChance(gen, _attacker, _defender, _move, _field, dict.damage);

  let attackerPower = "";
  let defenderPower = "";
  
  if(_move.category === "Physical"){

    if(_attacker.boosts.atk > 0){
      attackerPower = `+${_attacker.boosts.atk} `
    } else{
      if(_attacker.boosts.atk < 0) {
        attackerPower = `${_attacker.boosts.atk} `
      }
    }

    attackerPower = `${_attacker.rawStats.atk}`;
    if( get_nature_boost(dict.attacker, 'atk') > 1.01 ){
      attackerPower += "+"
    } else {
      if( get_nature_boost(dict.attacker, 'atk') < 0.99 ){
        attackerPower += "-"
      }
    }
    attackerPower += ' Atk';
  }else{

    if(_attacker.boosts.spa > 0){
      attackerPower = `+${_attacker.boosts.spa} `
    } else{
      if(_attacker.boosts.spa < 0) {
        attackerPower = `${_attacker.boosts.spa} `
      }
    }

    attackerPower = `${_attacker.rawStats.spa}`;
    if( get_nature_boost(dict.attacker, 'spa') > 1.01 ){
      attackerPower += "+"
    } else {
      if( get_nature_boost(dict.attacker, 'spa') < 0.99 ){
        attackerPower += "-"
      }
    }
    attackerPower += ' SpA';
  }

  
  let _max_name = getMaxMoveName(_move.type, _attacker.species.name)

  attackerPower += ` ${_attacker.ability} ${_attacker.item} ${_attacker.name} ${_attacker.isDynamaxed ? _max_name : _move.name} `

  if(dict.crit) attackerPower += '(Crit) ';

  if(_move.defensiveCategory === "Physical"){

    if(_defender.boosts.def > 0){
      defenderPower += `+${_defender.boosts.def} `
    } else{
      if(_defender.boosts.def < 0) {
        defenderPower += `${_defender.boosts.def} `
      }
    }

    defenderPower = `${_defender.rawStats.def}`;
    if( get_nature_boost(dict.defender, 'def') > 1.01 ){
      defenderPower += "+"
    } else {
      if( get_nature_boost(dict.defender, 'def') < 0.99 ){
        defenderPower += "-"
      }
    }
    defenderPower += ' Def';
  }else{

    if(_defender.boosts.spd > 0){
      defenderPower = `+${_defender.boosts.spd} `
    } else{
      if(_defender.boosts.spd < 0) {
        defenderPower = `${_defender.boosts.spd} `
      }
    }

    defenderPower = `${_defender.rawStats.spd}`;
    if( get_nature_boost(dict.defender, 'spd') > 1.01 ){
      defenderPower += "+"
    } else {
      if( get_nature_boost(dict.defender, 'spd') < 0.99 ){
        defenderPower += "-"
      }
    }
    defenderPower += ' SpD';
  }

  defenderPower += ` / ${ _defender.isDynamaxed ? 2*_defender.originalCurHP : _defender.originalCurHP} Hp`
  defenderPower += ` ${_defender.ability} ${_defender.item} ${_defender.name}`

  let _def_hp = _defender.isDynamaxed ? 2*_defender.rawStats.hp : _defender.rawStats.hp;

  let min_perc_damage = Math.floor( (1000.0*dict.damage[0 ])/_def_hp)/10.0;
  let max_perc_damage = Math.floor( (1000.0*dict.damage[dict.damage.length-1])/_def_hp)/10.0;

  let final_text = 
    attackerPower + 'vs ' + 
    defenderPower + ': ' +
    `${dict.damage[0]} - ${dict.damage[dict.damage.length -1]} ` +
    `(${min_perc_damage}% - ${max_perc_damage}%)` +
    ' -- ' + result.text;
  return final_text;
}



function getSmogonPokemon(gen, pokemon, evs=null){
  let _evs = null;
  if( evs === null){
    _evs = pokemon.evs;
  } else {
    _evs = evs;
  }

  let _curHP = pokemon.isDynamax ? Math.floor(pokemon.originalCurHP/2.0) : pokemon.originalCurHP;

  return new Pokemon(gen, pokemon.species, {
    level:  pokemon.level,
    nature: pokemon.nature,
    ability: pokemon.ability,
    evs:    _evs,
    ivs:    pokemon.ivs,
    boosts: pokemon.boosts,
    item: pokemon.item,
    originalCurHP: _curHP,
    isDynamaxed: pokemon.isDynamax
  });
}


export function Api2SmogonString(api_string){
    let no_hyphen = api_string.replace(/-/g, ' ');
    let words = no_hyphen.split(" ");
    let cap_words = words.map(word => {
      return capitalize(word);
    });
    return cap_words.join(' ');
}

export function capitalize( [first, ...rest], lower_rest=true){
  return first.toUpperCase() + (lower_rest ? rest.join('').toLowerCase() : rest.join(''));
}


export function calculate_damage(attacker, defender, move, field, crit=false){
  let gen8 = Generations.get(8);
  
  let p1 = getSmogonPokemon(gen8, attacker);
  let p2 = getSmogonPokemon(gen8, defender);
  let sm_field = getSmogonField(field);
  let _move = new Move(gen8, move);
  _move.useMax = p1.isDynamaxed;
  _move.isCrit = crit;
  return calculate(gen8, p1, p2, _move, sm_field);
}


export function get_ev_list(){
  let ev_list = [0]
  for( var i=0; i<32; i++ ){
    ev_list.push( 4+i*8 )
  }
  return ev_list
}


export function get_stat_bump(pk, stat_name){
  let bump = [];
  let ev_list = get_ev_list();
  let stat_list = get_stat_list(pk, stat_name);


  for( let i in stat_list ){
    if(i===0) continue;
    if(stat_list[i]-stat_list[i-1]>1) bump.push(ev_list[i]);
  }

  return bump;
}

export function get_stat_list(pk, stat_name){

  let stat_list = [];
  let ev_list = get_ev_list();

  for( var i in ev_list){
    stat_list.push( calculate_stat(pk, stat_name, ev_list[i]) );
  }

  return stat_list;
}



export function calculate_stat(pokemon, stat, ev=null){
  let this_ev = 0;
  if( ev === null){
    this_ev = pokemon.evs[stat];
  }
  else{
    this_ev = ev;
  }

  let boost = Math.pow((Math.abs(pokemon.boosts[stat])+2.0)/2.0, Math.sign( pokemon.boosts[stat] ));
  let nature_boost = get_nature_boost(pokemon, stat);
  if( stat === 'hp' )
  {
    let hp = Math.trunc( (Math.trunc( ((2*pokemon.stats[stat])+pokemon.ivs[stat]+ Math.trunc( this_ev/4.0 ))*pokemon.level/100.0 ) + pokemon.level + 10)*boost);
    if(!pokemon.isDynamax){
      return hp;
    } else {
      return 2*hp;
    }
  }
  return Math.trunc( (Math.trunc((Math.trunc( ((2*pokemon.stats[stat])+pokemon.ivs[stat]+ Math.trunc( this_ev/4.0 ))*pokemon.level/100.0 ) + 5)*nature_boost))*boost);
}

export function get_nature_boost(pokemon, stat_name){
  if( pokemon.nature === 'Lonely' ){
    if( stat_name === 'atk')
      return 1.1
    if( stat_name === 'def')
      return 0.9
  }
  if( pokemon.nature === 'Brave' ){
    if( stat_name === 'atk')
      return 1.1
    if( stat_name === 'spe')
      return 0.9
  }
  if( pokemon.nature === 'Adamant' ){
    if( stat_name === 'atk')
      return 1.1
    if( stat_name === 'spa')
      return 0.9
  }
  if( pokemon.nature === 'Naughty' ){
    if( stat_name === 'atk')
      return 1.1
    if( stat_name === 'spd')
      return 0.9
  }

  if( pokemon.nature === 'Bold' ){
    if( stat_name === 'def')
      return 1.1
    if( stat_name === 'atk')
      return 0.9
  }
  if( pokemon.nature === 'Relaxed' ){
    if( stat_name === 'def')
      return 1.1
    if( stat_name === 'spe')
      return 0.9
  }
  if( pokemon.nature === 'Impish' ){
    if( stat_name === 'def')
      return 1.1
    if( stat_name === 'spa')
      return 0.9
  }
  if( pokemon.nature === 'Lax' ){
    if( stat_name === 'def')
      return 1.1
    if( stat_name === 'spd')
      return 0.9
  }

  if( pokemon.nature === 'Timid' ){
    if( stat_name === 'spe')
      return 1.1
    if( stat_name === 'atk')
      return 0.9
  }
  if( pokemon.nature === 'Hasty' ){
    if( stat_name === 'spe')
      return 1.1
    if( stat_name === 'def')
      return 0.9
  }
  if( pokemon.nature === 'Jolly' ){
    if( stat_name === 'spe')
      return 1.1
    if( stat_name === 'spa')
      return 0.9
  }
  if( pokemon.nature === 'Naive' ){
    if( stat_name === 'spe')
      return 1.1
    if( stat_name === 'spd')
      return 0.9
  }

  if( pokemon.nature === 'Modest' ){
    if( stat_name === 'spa')
      return 1.1
    if( stat_name === 'atk')
      return 0.9
  }
  if( pokemon.nature === 'Mild' ){
    if( stat_name === 'spa')
      return 1.1
    if( stat_name === 'def')
      return 0.9
  }
  if( pokemon.nature === 'Quiet' ){
    if( stat_name === 'spa')
      return 1.1
    if( stat_name === 'spe')
      return 0.9
  }
  if( pokemon.nature === 'Rash' ){
    if( stat_name === 'spa')
      return 1.1
    if( stat_name === 'spd')
      return 0.9
  }

  if( pokemon.nature === 'Calm' ){
    if( stat_name === 'spd')
      return 1.1
    if( stat_name === 'atk')
      return 0.9
  }    if( pokemon.nature === 'Gentle' ){
    if( stat_name === 'spd')
      return 1.1
    if( stat_name === 'def')
      return 0.9
  }    if( pokemon.nature === 'Sassy' ){
    if( stat_name === 'spd')
      return 1.1
    if( stat_name === 'spe')
      return 0.9
  }    if( pokemon.nature === 'Carefull' ){
    if( stat_name === 'spd')
      return 1.1
    if( stat_name === 'spa')
      return 0.9
  }
  return 1.0;
}

