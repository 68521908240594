import {useState} from "react";
import "./Box.css";
import { Button, TextField } from "@mui/material";
import { parse_showdown_pokemon } from "./utils";
import BoxCard from "./BoxCard";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Box = ({pokemonBase, pokemonBox, setPokemonBox, pokemonSlot1, setPokemonSlot1, pokemonSlot2, setPokemonSlot2}) => {

  const [pc, setPC] = useState(false);
  const [importField, setImportField] = useState("");

  const changePCState = () => {
    setPC(value => !value);
  };

  const importPokemon = (text) => {
    let showdown_pokemons = text.split("\n\n");
    for(let i in showdown_pokemons){
      let new_pk = parse_showdown_pokemon(showdown_pokemons[i], JSON.parse(  JSON.stringify(pokemonBase)))
      if(new_pk.uuid !== pokemonBase.uuid)
      {
        setPokemonBox( prevBox => [...prevBox, new_pk]);
      }
    }
    setImportField("");
  }

  return (
  <div className="Box">

    <Accordion 
        expanded={pc}
        onChange={changePCState}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className="pc_button">
          <img src={pc ? "Box/PC_box_on.png" : "Box/PC_box_off.png"} alt="pc"></img>
        </div>
        <Typography> Open Box </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="BoxBar">
          <div className="ImportContainer">
            <TextField 
              margin="normal"
              variant="filled"
              id="InputShowdown"
              placeholder="Import from Showdown..."
              multiline
              style={{height:330, width:"100%", overflow:'auto'}}
              value={importField}
              onChange={(e) => {setImportField(e.target.value)}}
              className="ShowdownInput"></TextField>
            <Button variant="outlined" onClick={()=>{importPokemon(importField)}}>Import</Button>
          </div>
          <div className="BoxContainer" style={{backgroundImage:"url(Box/BoxBackground.png)"}}>
            {pokemonBox.map( (element) => {return( 
              <BoxCard key={element.uuid} 
                pokemon={element} setPokemonBox={setPokemonBox}
                pokemonSlot1={pokemonSlot1} setPokemonSlot1={setPokemonSlot1} 
                pokemonSlot2={pokemonSlot2} setPokemonSlot2={setPokemonSlot2} 
              /> ) } )  }
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  </div>);
}

export default Box;
