import {useState} from "react";
import { Rocket } from "@mui/icons-material";
import { Typography, Popover } from "@mui/material";
import { get_stat_bump } from "./utils";

const StatBump = ({pokemon, stat}) => {

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  let bump = get_stat_bump(pokemon, stat);


  return(
    <div className="BumpIcon">      
      <Rocket color="action" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}/>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{`Bump for EVs equal to ${bump[0]}, ${bump[1]} and ${bump[2]}`}</Typography>
      </Popover> 
    </div>
  );
}

export default StatBump;