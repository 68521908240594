import React from "react";
import {Autocomplete, TextField, ToggleButton, Typography} from "@mui/material";
import "./PokemonMoveSelection.css";

const PokemonMoveSelection = ({pokemon, setPokemon, move_id}) =>{
  
  const changeMove = (e, newValue, id) => {
    if( newValue === null || newValue === "") return;
    setPokemon( prevPokemon => {
      let _moveset = prevPokemon.moveset;
      _moveset[id] = newValue;
      return {...prevPokemon, _moveset}; 
    });
  };

  const changeCrit = (id) => {
    setPokemon( prevPokemon => {
      let _moveCrit = prevPokemon.moveCrit;
      _moveCrit[id] = !_moveCrit[id];
      return {...prevPokemon, moveCrit: _moveCrit}
    })
  }

  return (
    <div className="MoveSelectionLine">
      <div className='MoveSelectionAutocomplete'>
      <Autocomplete
        fullWidth
        options={pokemon.moves}
        autoSelect
        renderInput={(params) => <TextField {...params} label={"Moves"} sx={{width: '200px', margin:'10px 10px 10px 0px'}} size="small" margin="normal"/>}
        onChange={(e, newValue) => {
            changeMove(e, newValue, move_id);
        }}
        value={pokemon.moveset[move_id]} 
      />
      </div>
      <div className="MoveSelectionCritButton">
        <ToggleButton value='crit' size='small' fullWidth selected={pokemon.moveCrit[move_id]} onChange={() => {changeCrit(move_id)}} disabled={pokemon.moveset[move_id] === null || pokemon.moveset[move_id] === ""} >
          <Typography>Crit</Typography>
        </ToggleButton>
      </div>
    </div>);
}


export default PokemonMoveSelection;