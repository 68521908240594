import {useEffect, useState} from "react";
import SearchButton from "./SearchButton";
import PokemonSlotDisplay from "./PokemonSlotDisplay";
import "./CardSlot.css";
import PokemonBuilder from "./PokemonBuilder";
import Side from "./Side";


import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Typography, TextField } from "@mui/material";
import { calculate_stat } from "./utils";

import DotOptimizerPlot from "./DotOptimizerPlot";


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const CardSlot = ({slot_id, pokemon, setPokemon, pokemonBase, side, setSide, setPokemonBox}) => {

  const [expanded, setExpanded] = useState(null);
  const [maxCurHpValue, setMaxCurHpValue] = useState(calculate_stat(pokemon, 'hp'));

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const changeCurHP = (e) => {

    let curhp = 0    
    if( e.target.value === "" || e.target.value === null || isNaN(e.target.value)){ 
      curhp = 0;
    } else {
      curhp = parseInt(e.target.value)
      curhp = Math.min( curhp, maxCurHpValue ) 
    }

    setPokemon( prevPokemon => ({
      ...prevPokemon,
      originalCurHP: curhp
    }));
  };

  useEffect( () => {
    let x = 
      Math.trunc( 
        (Math.trunc( ((2*pokemon.stats.hp)+pokemon.ivs.hp+ Math.trunc( pokemon.evs.hp/4.0 ))*pokemon.level/100.0 ) 
        + pokemon.level + 10));
    setMaxCurHpValue( pokemon.isDynamax ? 2*x : x );
    
  }, [pokemon.stats.hp, pokemon.evs.hp, pokemon.ivs.hp, pokemon.level, pokemon.isDynamax] );

  return (
    <div className="CardSlot" id={slot_id}>
      <SearchButton pokemon={pokemon} setPokemon={setPokemon} pokemonBase={pokemonBase} setPokemonBox={setPokemonBox}/>
      <PokemonSlotDisplay pokemon={pokemon} />
      
      <div className="buttonBar">
        <Typography
          className="curhp_label"
        >Current HP: </Typography>
        <TextField
          className="curhp_input"
          label=""
          type="number"
          size="small"
          value={pokemon.originalCurHP}
          onChange={changeCurHP}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Typography
          className="curhp_num"
        > /{maxCurHpValue} </Typography>
        <Typography
          className="curhp_perc"
        >({(Math.floor( 1000.0*pokemon.originalCurHP/maxCurHpValue)/10.0).toFixed(1) }%) </Typography>
      </div>

      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Open Builder</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PokemonBuilder pokemon={pokemon} setPokemon={setPokemon}></PokemonBuilder>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Open Field Side</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Side side={side} setSide={setSide} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Open Dot Optimizer</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DotOptimizerPlot pokemon={pokemon} />
        </AccordionDetails>
      </Accordion>
    </div>
  );

}

export default CardSlot;