import { Typography } from "@mui/material";
import React from "react";
import "./Notes.css";
import NoteCard from "./NoteCard";

const Notes = ({damageList, setDamageList}) => {



  return(
    <div className="Notes">
      <Typography> Damage List </Typography>
      <div className="NoteList">
        {damageList.map( element => {return <NoteCard key={element.id} dict={element} setDamageList={setDamageList} />;} ) }
      </div>
    </div>
  );
}

export default Notes;