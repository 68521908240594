import React from "react";
import { Typography } from "@mui/material";
import "./NoteCard.css";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ButtonIcon from "@mui/material/IconButton";
import CancelIcon from '@mui/icons-material/Cancel';

const NoteCard = ({dict, setDamageList}) => {

  return(
    <div className="NoteCard">
        <div className="NoteCardButton" >
          <ButtonIcon color="fire" onClick={()=>{
            setDamageList( prevList => prevList.filter( (element) => {return element.id !== dict.id} ) )
          }}>
            <CancelIcon />
          </ButtonIcon>
        </div>
      <div className="MiniCard">
        <div className="NoteCardImg">
          <img alt="attacker_animation" src={dict.attackerAnimation} ></img>
        </div>
        <div className="NoteCardTextSlot">
          <Typography>{`${dict.attackerAttack} ${dict.attackerMax ? '(Max)' : '' }`}</Typography>
        </div>
        <div className="NoteCardTextSlot">
          <Typography>{dict.attackerItem}</Typography>
        </div>
        <div className="NoteCardTextSlot">
          <Typography>{dict.attackerAbility}</Typography>
        </div>
        <div className="NoteCardTextSlot">
          <Typography>{dict.attackerNature}</Typography>
        </div>
      </div>
        <div className="NoteCardArrow">
        <ArrowForwardIosIcon />
      </div>
      <div className="MiniCard">
        <div className="NoteCardImg">
          <img alt="defender_animation" src={dict.defenderAnimation} ></img>
        </div>
        <div className="NoteCardTextSlot">
          <Typography>{`${dict.defenderDefense}`}</Typography>
        </div>
        <div className="NoteCardTextSlot">
          <Typography>{dict.defenderItem}</Typography>
        </div>
        <div className="NoteCardTextSlot">
          <Typography>{dict.defenderAbility}</Typography>
        </div>
        <div className="NoteCardTextSlot">
          <Typography>{dict.defenderNature}</Typography>
        </div>
      </div>
      <div className="NoteCardDamageText">
      <Typography fontSize={"18pt"}>{dict.moveName}</Typography>
      <Typography>{dict.moveText.split(':')[1].split('--')[0]}</Typography>
      <Typography>{dict.moveText.split(':')[1].split('--')[1]}</Typography>
      </div>
    </div>
  );
}

export default NoteCard;